/* elements.css */

.main-btn-12 {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.main-btn-12:hover {
  background-color: #0056b3;
}

.title {
  font-size: 4rem;
  font-weight: 800;
  color: #007eeb;
  text-align: center;
  margin: 40px 0px 20px;
  text-shadow: -2px 2px 0px black;
}

.subtitle {
  font-size: 24px;
  font-weight: bold;
  color: #444;
}

.heading {
  font-size: 24px;
  font-weight: 400;
  color: #7b7b7b;
  text-align: center;
  margin: 0px 0px 32px;
}

.tagline {
  font-size: 16px;
  color: #666;
}

.paragraph {
  font-size: 16px;
  color: #777;
  line-height: 1.5;
  margin-bottom: 20px;
}

.speciality-card {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 24px #dddddd;
  height: 94%;
  transition: all 300ms ease-in-out;
  background: white;
}

.speciality-card:hover {
  box-shadow: 0px 0px 4px #007eeb;
  transform: scale(1.02);
}

.speciality-card img {
  height: 50%;
  object-fit: cover;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.speciality-card .special-content {
  padding: 16px;
  height: 35%;
}

.speciality-card p {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  font-family: Open Sans;
  font-size: 16px;
  line-height: 160%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.speciality-card .wrapper {
  padding: 0px 16px 0px;
  height: 5%;
}

.speciality-card h3 {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  font-family: Open Sans;
  font-size: 16px;
  line-height: 160%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #007eeb;
  font-weight: 600;
  font-size: 24px;
}

.link {
  font-size: 24px;
  font-weight: bold;
  color: black;
  text-decoration: none;
  position: relative;
  padding-left: 12px;
}

.link:hover {
  color: black;
  text-decoration: none;
}
.link span,
.link i {
  position: relative;
}
.link i {
  margin-left: 0.5em;
}
.link .color {
  width: 2em;
  height: 2em;
  border-radius: 2em;
  background-color: #d2e6cc;
  position: absolute;
  top: 50%;
  transform: translatey(-50%);
  left: 0;
}

.speciality-card span {
  color: #588e4a;
}

.carousel-div {
  width: 98%;
}

.slick-card {
  width: 95% !important;
  height: 100%;
  border-left: 2px solid #007eeb;
  padding-left: 12px;
  border-top: 2px solid #007eeb;
  border-top-left-radius: 8px;
  box-shadow: -4px -4px 2px 0px #007eeb40;
  margin-top: 12px;
  margin-left: 4px;
  padding-bottom: 22px;
  background: white;
}
.slick-card img {
  height: 40%;
  width: 80%;
}

.slick-card h3 {
  font-size: 24px;
  color: #007eeb;
  font-weight: 600;
}

.slick-card p {
  font-size: 16px;
  line-height: 160%;
  margin-top: 12px;
}

.why-choose-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  box-shadow: 0px 0px 20px #d7d7d7;
  border-radius: 8px;
  height: 100%;
  padding: 20px;
  background: white;
}

.why-choose-card:hover {
  box-shadow: 0px 0px 8px #007eeb;
}

.why-choose-card h4 {
  font-size: 24px;
  color: #007eeb;
  font-weight: 600;
  margin: 8px 0px;
}

.why-choose-card p {
  font-size: 16px;
  margin: 0px 0px 0px;
  line-height: 160%;
}

.service-card {
  width: 90%;
  margin: auto;
  box-shadow: 0px 0px 4px grey;
  margin-bottom: 24px;
  border-radius: 8px;
}
.service-card div {
  padding: 12px;
}

.service-card h3 {
  font-size: 24px;
  color: #007eeb;
  font-weight: 600;
  margin: 8px 0px;
}

.service-card p {
}

@media only screen and (max-width: 767px) {
  .title {
    font-size: 3rem;
    line-height: 110%;
  }

  .heading {
    font-size: 18px;
    font-weight: 400;
    margin: 0px 8px 32px;
    line-height: 140%;
  }

  .moblie-btn {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    text-align: center;
    z-index: 99;
  }
}
