.Contact-part {
  padding: 8rem 5rem 5rem 3rem;
}

.contact-heading {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 18px;
}

.contact-paragraph {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 24px;
}

.contact-paragraph1 {
  margin-left: 10px;
  color: #612e8a;
  font-size: 22px;
}

.contact-input {
  border-radius: 23px;
  border: 1px solid #00000066;
  padding: 8px 10px;
}

.send-msg-btn {
  font-family: "Open Sans", sans-serif !important;
  background-color: #007eeb !important;
  color: white !important;
  padding: 8px 15px;
  border-radius: 20px;
  width: 100%;
}

.contact-getintouch-part {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #0000006b;
  border-radius: 31px;
  border-radius: 20px;
  background: linear-gradient(45deg, white, #e9dafa);
}

.swal2-title {
  font-family: "Open Sans", sans-serif;
  color: black !important;
}

.swal2-html-container {
  font-family: "Open Sans";
}

.swal2-styled.swal2-confirm {
  background-color: #044b61;
  font-family: "Open Sans", sans-serif;
  color: white;
  padding: 8px 30px;
  border-radius: 20px;
  text-transform: uppercase;
}

.contact-getintouch-part h4 {
  font-size: 20px;
  color: #a86ae2 !important;
}

@media only screen and (min-width: 1910px) {
  .Contact-part {
    padding: 100px 18rem 40px 17rem;
  }
}

@media only screen and (max-width: 767px) {
  .Contact-part {
    padding: 3rem 2rem;
  }

  .contact-getintouch-part {
    padding: 20px 30px;
    margin: 30px 15px;
    padding-bottom: 0px;
  }
}

/* ------ Flipkart CSS ------ */
.category-images {
  border: 1px solid black;
  padding: 20px;
}
