/* -------------********************************---------------- 
                          MAIN STYLING
 --------------- ********************************---------------- */

/* -------------********************************---------------- 
        Styling for responsiveness can be found at the end of the document 
-------------********************************----------------  */

.HeroStyles {
  padding-top: 1px;
  padding-bottom: 1px;
}

.test-img {
  width: 180px;
  margin: 10px auto;
  border-radius: 20px;
}

.top-cont-hero {
  text-decoration: none;
  color: aliceblue;
  background-color: #000;
  border-radius: 12px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 16px;
  padding: 8px 16px;
  -webkit-flex-align: center;
  -ms-flex-align: center;
  flex-align: center;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  /* cursor: pointer; */
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.top-title-cont {
  margin-top: 40px;
  margin: 0;
  font-family: "Darker Grotesque", sans-serif;
  font-size: 80px;
  line-height: 1em;
  font-weight: 900;
  font-style: normal;
  letter-spacing: -0.03em;
  color: #000000;
  margin-top: 24px;
}

.Testimonials-content {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 24px;
}

.Testimonial-hr {
  height: 4px;
  background: #044b61;
  width: 10%;
  margin: 10px auto !important;
  margin-top: 20px !important;
}

.Testimonials-slide-heading {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 18px;
}

.Testimonials-part {
  padding: 10px 60px;
  background: linear-gradient(
    360deg,
    white,
    #007deb24,
    rgb(0 128 0 / 3%),
    #007deb21,
    white
  );
}

.swiper-pagination-bullet-active {
  background: #612e8a !important;
}

.Testimonial-slide {
  text-align: center;
  margin-bottom: 40px;
  padding: 20px 40px;
  display: flex;
  flex-direction: row;
}

.Testimonial-slide-left {
  width: 30%;
}

.Testimonial-slide-right {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.testimonial-slide-div {
  padding: 20px !important;
  border: 2px dotted #612e8a;
  border-radius: 8px;
}

.testimonial-slide-div-outer {
  padding: 10px;
  border: 2px dotted #612e8a;
  border-radius: 8px;

  margin: auto;
}

.Testimonial-slide-New {
  text-align: left;
  margin-bottom: 24px;
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  border: 1px solid #612e8a;
  border-radius: 14px;
  gap: 8px;
}

.Testimonial-slide-left-New {
  width: 100%;
  display: flex;
  gap: 16px;
}

.Testimonial-slide-left-New img {
  width: 20%;
  margin: 0px;
  border-radius: 100%;
}

.Testimonial-slide-right-New {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.testimonial-slide-div-New {
  padding: 20px !important;
  border: 2px dotted #612e8a;
  border-radius: 8px;
}

.testimonial-slide-div-outer-New {
  padding: 10px;
  border: 2px dotted #612e8a;
  border-radius: 8px;

  margin: auto;
}

.Testimonials-slide-heading-New {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 18px;
}

.Testimonials-content-New {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 24px;
}

.container1 {
  overflow: hidden;
  height: 60vh;
  margin: 46px 0px;
  position: relative;
}

.vertical-div {
  display: flex;
  flex-direction: column;
  animation: scroll 140s linear infinite; /* Adjust duration as needed */
}

@keyframes scroll {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(calc(-80%)); /* Adjust the margin as needed */
  }
}

.vertical-div2 {
  display: flex;
  flex-direction: column;
  animation: scroll2 130s linear infinite; /* Adjust duration as needed */
}

@keyframes scroll2 {
  0% {
    transform: translateY(-20%);
  }
  100% {
    transform: translateY(0%); /* Adjust the margin as needed */
  }
}

.vertical-div3 {
  display: flex;
  flex-direction: column;
  animation: scroll3 160s linear infinite; /* Adjust duration as needed */
}

@keyframes scroll3 {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-60%); /* Adjust the margin as needed */
  }
}

.vertical-div4 {
  display: flex;
  flex-direction: column;
  animation: scroll4 120s linear infinite; /* Adjust duration as needed */
}

@keyframes scroll4 {
  0% {
    transform: translateY(-20%);
  }
  100% {
    transform: translateY(0%); /* Adjust the margin as needed */
  }
}

/* -------------********************************---------------- 
                  STYLING FOR RESPONSIVE SCREENS 
--------------- ********************************---------------- */

/* -------------********************************---------------- 
                    EXTRA LARGE SCREEN - Min 1910px 
--------------- ********************************---------------- */

@media only screen and (min-width: 1910px) {
  .Testimonials-part {
    padding: 10px 19rem;
  }
}

@media only screen and (max-width: 767px) {
  .HeroStyles {
    padding-top: 60px;
    padding-bottom: 50px;
  }

  .top-title-cont {
    font-size: 45px;
  }
  .Testimonials-part {
    padding: 0px 24px;
  }
}
