/* CSS for the header/navbar */
.navbar-nav .nav-item:hover .dropdown-menu {
  display: block;
}

.dropdown-menu {
  display: none;
  position: absolute;
  z-index: 1000;
}

.navbar-nav {
  width: 100%;
}

@media (min-width: 768px) {
  .navbar-nav {
    display: flex;
    justify-content: center;
  }
}
.navbar-items {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
  color: green;
}

.navbar-items ul {
  gap: 12px;
}

.header-logo {
  width: 170px;
}

.navbar {
  background: white !important;
  /* backdrop-filter: blur(100px); */
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9;
  width: 100%;
}

.navbar-expanded {
  background: white !important;
}

@media only screen and (max-width: 767px) {
  .navbar {
    padding: 0px 8px;
  }
}
