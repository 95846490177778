.slider-container {
  position: relative; /* Ensure the container has a position */
}

.awesome-slider {
  width: 100%; /* Set the width of the slider */
}

.slide {
  position: relative; /* Ensure the slide has a position */
  width: 100%;
  height: 100%;
}

.slide-image {
  width: 100%; /* Ensure the image fills the slide */
  height: 100%; /* Maintain aspect ratio */
  object-fit: cover; /* Ensure the image covers the entire slide */
}

.hero-content {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.hero-title {
  color: #007bbe;
  font-weight: 800;
  text-shadow: 0px 2px 0px white;
  text-align: center;
}
