.footer-box {
  background: #007eeb;
}

.footer-part-one {
  background-color: #3a1358;
  padding: 40px 80px 80px;
  clip-path: ellipse(75% 100% at center top);
  text-align: center;
  font-family: "Open Sans", sans-serif;
  background: url(./../../Assets/Media/FooterBg3.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.footer-part-two {
  padding: 40px 80px 40px;
  --bs-gutter-x: 0rem;
  font-family: "Open Sans", sans-serif;
}

.footer-part-three {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 80px 0px;
  border-top: 1px solid #ffffff82;
  padding-top: 20px;
  padding-bottom: 30px;
  font-family: "Open Sans", sans-serif;
}
.terms-part-footer {
  display: flex;

  font-size: 18px;
  font-weight: 600;
  color: white;
}

.subscribe-form {
  display: flex;
  flex-direction: column;
  width: 60%;
  justify-content: center;
  margin: 30px auto 0px;
  gap: 15px;
}

@media only screen and (min-width: 1910px) {
  .footer-box {
    /* padding: 40px 17rem; */
  }
  .footer-part-one {
    padding: 60px 17rem 100px;
  }
  .footer-part-two {
    padding: 40px 20rem 40px;
  }
  .footer-part-three {
    padding: 15px 1rem 20px;
    margin: 0px 19rem;
  }
}

.subscribe-btn {
  width: 50%;
  display: flex;
  color: var(--text-white);
  text-transform: uppercase;
  justify-content: center;
  gap: 10px;
  background: var(--text-primary);
  align-items: center;
  padding: 3px 10px;
  border-radius: 8px;
  border: 2px solid var(--text-primary);
}

.subscribe-btn:hover {
  background-color: transparent;
  color: white;
  border: 2px solid white;
}

.subscribe-btn:hover .subscribe-icon {
  color: white !important;
}

.footer-logo {
  max-width: 50%;
}

.subscribe-input {
  width: 50%;
  position: relative;
  height: 100%;
  background: transparent !important;
  border: 1px solid white;
  border-bottom: 1px solid white;
  /* border-radius: 0px !important; */
  color: var(--text);
  opacity: 100%;
  padding: 0px;
  padding: 8px 10px 6px;
  border-radius: 8px;
}

.subscribe-input:focus {
  border: 1px solid white;
  background-color: var(--text-white) !important;
  outline: 0px !important;
  box-shadow: none;
}

.footer-links-section {
  margin-left: 135px;
}

.footer-links-section-2 {
  margin-left: 50px;
}

.footer_links {
  font-size: 16px;
  line-height: 32px;
}

.footer_links:hover {
  text-decoration: underline !important;
  color: white !important;
}
.text-underline {
  position: relative;
  display: inline-block;
  text-decoration: none;
}

.text-underline:after {
  content: "";
  position: absolute;
  border-bottom: 2px solid var(--text-primary);
  top: 85%;
  transition: all 0.4s;
  right: 100%;
  left: 0;
}

.timeline-item-content:hover .text-underline:after {
  right: 0;
}

.timeline-item-content:hover {
  color: white;
}

.footer-link123:hover {
  text-decoration: underline !important;
  color: white !important;
}

.copy-box-home-vision {
  text-align: left;
  background-color: var(--text-white) !important;
  color: var(--text) !important;
  z-index: 9;
  margin-right: 20px !important;
  font-weight: 900;
  border: 2px solid #002549;
  border-radius: 8px;
}

.swal2-styled.swal2-confirm {
  background-color: #002549;
}

.t-footer-icon:hover {
  transform: scale(1.3);
  cursor: pointer;
}

.t-footer-icon:hover .fb {
  color: #3b5998;
  transform: scale(1.2);
}

.t-footer-icon:hover .yt {
  color: #cd201f;
  transform: scale(1.2);
}

.t-footer-icon:hover .twitter {
  color: #00acee;
  transform: scale(1.2);
}
.t-footer-icon:hover .insta {
  color: #4f5bd5;
  transform: scale(1.2);
}

.blog-hand1 {
  height: 33px;
  width: 33px;
  margin-bottom: -11px;
  margin-right: 5px;
}

.anchor-main:hover {
  color: white;
}

@media only screen and (max-width: 767px) {
  .footer-box {
    margin-bottom: 40px;
  }

  .subscribe-btn {
    width: 100%;
    left: 0px;
  }

  .subscribe-input {
    width: 100%;
    left: 0px;
  }

  .footer-links-section {
    margin-left: 0px;
  }
  .footer-links-section-2 {
    margin-left: 0px;
  }
  .footer-part-one {
    padding: 40px 20px 80px;
    clip-path: ellipse(110% 100% at center top);
  }
  .footer-part-two {
    flex-direction: column;
    padding: 40px 10px 12px;
    gap: 24px;
  }
  .footer-part-three {
    flex-direction: column;
    margin: 20px 20px 0px;
    gap: 20px;
    text-align: left;
    justify-content: start;
    align-items: baseline;
  }
  .terms-part-footer {
    flex-direction: column;
  }
  .subscribe-form {
    flex-direction: column !important;
  }
}
