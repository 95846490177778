/* -------------********************************---------------- 
                          MAIN STYLING
 --------------- ********************************---------------- */

/* -------------********************************---------------- 
        Styling for responsiveness can be found at the end of the document 
-------------********************************----------------  */

.gold-product-component {
  display: flex;
  flex-direction: row;
  padding: 40px 5rem;
  gap: 50px;
}

.gold-product-component1 {
  display: flex;
  flex-direction: row;
  padding: 5px 5rem;
  gap: 50px;
}

.gold-product-component .main-title {
  text-align: left;
  margin-bottom: 0px;
  font-family: "Open Sans", sans-serif;
  color: #007eeb;
}

.gold-product-component1 .main-title {
  text-align: left;
  margin-bottom: 0px;
}

.gold-product-component-content {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* margin-top: 4%; */
}

.gold-product-component-image-div {
  display: flex;
  width: 40%;
}

.gold-product-component-image {
  z-index: 2;
  border-radius: 12px;
  box-shadow: -9px 9px 0px 0px #007eeb;
  transition: all 300ms ease-in-out;
}

.gold-product-component-image:hover {
  box-shadow: 9px 9px 0px 0px #558e4a;
  transform: scale(0.99);
  transform: rotate3d(45deg);
}

.gold-product-component-image1 {
  z-index: 2;
  border-radius: 12px;

  /* margin-left: 15px; */
}

.about-us-paragraph {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 24px;
}

/* -------------********************************---------------- 
                  STYLING FOR RESPONSIVE SCREENS 
--------------- ********************************---------------- */

/* -------------********************************---------------- 
                    EXTRA LARGE SCREEN - Min 1910px 
--------------- ********************************---------------- */

@media only screen and (min-width: 1910px) {
  .gold-product-component {
    padding: 50px 19rem;
  }

  .gold-product-component1 {
    padding: 50px 19rem;
  }
}

/* -------------********************************---------------- 
                   MOBILE SCREEN - Max - 767px 
--------------- ********************************---------------- */

@media only screen and (max-width: 767px) {
  .gold-product-component {
    flex-direction: column;
    width: 100%;
    padding: 50px 30px;
  }

  .gold-product-component1 {
    flex-direction: column;
    width: 100%;
    padding: 50px 30px;
  }

  .gold-product-component-content {
    width: 100%;
  }

  .gold-product-component-image-div {
    width: 100%;
  }

  .gold-product-component-image {
    width: 100%;
    height: 330px;
  }

  .gold-product-component-image1 {
    width: 100%;
    height: 330px;
  }

  .bg-div {
    width: 84%;
    height: 330px;
  }

  .bg-div1 {
    width: 85%;
    height: 330px;
  }

  .comp2-content {
    order: 1;
  }

  .comp2-image {
    order: 2;
  }
}
